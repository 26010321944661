import React from 'react';
import { Link } from 'gatsby';
import format from 'date-fns/format';
// import { INSCRIPCION_ROUTE } from '../../constants/routes';

const ForthcomingCard = ({ cardData, tableData, isEnglish, btnRoute }) => {
  return (
    <div className="c-fourth-course-card">
      <div className="c-row">
        <span className="c-title-16">
          <span className="hidden">{tableData.course || 'Course'}:</span>
          {cardData.name?.text}
        </span>
      </div>
      <div className="c-row c-text-14 from-to">
        <span className="hidden">{tableData.from || 'From'}:</span>
        {/* {!cardData.is_price_per_month ? cardData.from : format(new Date(cardData.from), 'MMMM')} */}
        {cardData.from}
      </div>
      <div className="c-row c-text-14 from-to from-to--offseted">
        <span className="hidden">{tableData.to || 'To'}:</span>
        {/* {!cardData.is_price_per_month ? cardData.to : format(new Date(cardData.to), 'MMMM')} */}
        {cardData.to}
      </div>
      {cardData?.timetable ? (
        <div className="c-row reduced c-text-14 from-to">
          <span className="hidden">{tableData.timetable || 'Timetable'}:</span>
          {/* {!cardData.is_price_per_month
            ? cardData?.timetable
            : format(new Date(cardData.to), 'MMMM')} */}
          {cardData?.timetable}
        </div>
      ) : null}
      <div className="c-row">
        <span className="c-title-24 price text-fw-600">
          <span className="hidden">{tableData.price || 'Price'}:</span>
          <span>€{cardData.price}</span>
          {!cardData.is_price_per_month ? '' : <span className="period">/mes</span>}
        </span>
      </div>

      {!cardData?.timetable ? (
        <div className="c-row">
          <p className="places-available">Plazas disponibles</p>
        </div>
      ) : null}

      <Link to={btnRoute} className="c-btn c-btn--green c-text-14">
        {isEnglish ? 'Sign up now' : 'Inscríbete'}
      </Link>
    </div>
  );
};

export default ForthcomingCard;
