import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Select from 'react-select';
import { INSCRIPCION_ROUTE } from '../../constants/routes';
import ForthcomingCard from '../atoms/ForthcomingCard';

const options = [
  { value: 'Nombre del curso', label: 'Nombre del curso' },
  { value: 'Curso FELE intensivo', label: 'Curso FELE intensivo' },
  { value: 'Curso ELE+', label: 'Curso ELE+' },
  {
    value: 'Discount Spanish Lessons',
    label: 'Discount Spanish Lessons',
  },
];
// const options2 = [{ value: 'Fecha de inicio', label: 'Fecha de inicio' }];

const CourseSection = ({
  title = false,
  description = false,
  cardData,
  tableData = false,
  isEnglish = false,
  isFilterable = false,
  btnRoute = INSCRIPCION_ROUTE,
}) => {
  const [filteredCardData, setFilteredCardData] = useState(cardData);
  const [filteredOption, setFilteredOption] = useState(options[0]);
  // const [filteredOption2, setFilteredOption2] = useState(options2[0]);

  useEffect(() => {
    setFilteredCardData(
      cardData.filter((item) => {
        if (filteredOption.value === options[0].value) {
          return item;
        } else if (item.node.data.name.text === filteredOption.value) {
          return item;
        }
      })
    );
  }, [filteredOption]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    control: (styles) => ({
      ...styles,
      // none of react-select's styles are passed to <Control />
      width: '100%',
      cursor: 'pointer',
    }),
    container: (styles) => ({ ...styles, marginRight: '1.5rem' }),
    indicatorSeparator: (styles) => ({ display: 'none' }),
  };
  return (
    <div className="forthcoming-courses" id="courses-cards">
      {title && (
        <>
          <div className="forthcoming-courses__header justify-content-between">
            <h2 className="c-title-34 forthcoming-courses__title">{title}</h2>
            {/* {!tableData?.timetable ? (
              <Link to="/courses/dates" className="c-btn c-btn--white">
                Ver todos los cursos
              </Link>
            ) : null} */}
          </div>
          {isFilterable ? (
            <div className="row no-gutters filter">
              <div className="col-md-4 col-lg-4">
                <div className="select-wrapper select-wrapper--with-margin">
                  <Select
                    defaultValue={options[0]}
                    onChange={(selectedOption) => {
                      setFilteredOption(selectedOption);
                    }}
                    styles={customStyles}
                    value={filteredOption}
                    options={options}
                  />
                </div>
              </div>
              {/* <div className="col-md-4 col-lg-4">
                <div className="select-wrapper">
                  <Select
                    defaultValue={options2[0]}
                    onChange={(selectedOption) => {
                      setFilteredOption2(selectedOption);
                    }}
                    styles={customStyles}
                    options={options2}
                    value={filteredOption2}
                  />
                </div>
              </div> */}
            </div>
          ) : null}
        </>
      )}
      <div className="forthcoming-courses__table">
        <div className="row cell cell--title">
          <div className="col-2 c-text-14">{tableData.course || 'Curso'}</div>
          <div className="col-2 c-text-14">{tableData.from || 'Desde'}</div>
          <div className="col-2 c-text-14">{tableData.to || 'Hasta'}</div>
          {tableData?.timetable ? (
            <div className="col-2 c-text-14">{tableData?.timetable}</div>
          ) : null}
          <div className="col-2 c-text-14">{tableData.price || 'Precio'}</div>
        </div>
        {filteredCardData.map(({ node }) => (
          <ForthcomingCard
            key={node.id}
            cardData={node.data}
            tableData={tableData}
            isEnglish={isEnglish}
            btnRoute={btnRoute}
          />
        ))}
      </div>
    </div>
  );
};

export default CourseSection;
