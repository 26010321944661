import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../../../components/Layout';
import CourseSection from '../../../components/molecules/CoursesSection';
import CFS from '../../../components/molecules/CFS';
import Checkmark from '../../../components/atoms/Checkmark';
import { INSCRIPCION_ROUTE } from '../../../constants/routes';

import imgSecSection from '../../../assets/images/pages/fechas-y-tarifas/pago.png';

import '../../../assets/styles/pages/fechas-y-tarifas.scss';

const FechasYTarifas = ({ data }) => {
  const allPrismicCourses = data?.allPrismicCourses.edges;
  return (
    <Layout
      title="Cursos ELE Barcelona «Saber mas sobre Precios y Fechas"
      description="Descubre mas sobre los precios y fechas e inscribete por internet"
    >
      <div className="fechas-y-tarifas">
        <CFS title="Fechas y tarifas" />
        <section className="second-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="second-section__title c-title-32">
                  Precios del curso FELE para 2024
                </h2>
              </div>
            </div>
            <div className="row extra-info-cards">
              <div className="col-md-6 col-12">
                <div className="course-card">
                  <div className="card__header">
                    <h3 className="header__title">Curso FELE intensivo de 4 semanas</h3>
                    <p className="header__description">Presencial</p>
                  </div>
                  <div className="card__center">
                    <div className="left">
                      <h4 className="left__title">Precio regular</h4>
                      <h4 className="left__price">1650€</h4>
                    </div>
                    <div className="right">
                      <h4 className="left__title">Precio con descuento</h4>
                      <h4 className="left__price">1500€</h4>
                    </div>
                    <p className="note">
                      Precio con descuento cuando pagas el importe total con 12 semanas de
                      antelación.
                    </p>
                  </div>
                  <div className="card__body"></div>
                  <div className="card__bottom">
                    <Link to={INSCRIPCION_ROUTE} className="c-btn c-btn--green">
                      Inscríbete ahora
                    </Link>
                    <Link to="#courses-cards" className="c-btn c-btn--green-border">
                      Ver fechas
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="course-card">
                  <div className="card__header">
                    <h3 className="header__title">Curso ELE+ de una semana</h3>
                    <p className="header__description">Presencial</p>
                  </div>
                  <div className="card__center">
                    <div className="left">
                      <h4 className="left__title">Precio</h4>
                      <h4 className="left__price">800€</h4>
                    </div>
                    <p className="note"> </p>
                  </div>
                  <div className="card__body"></div>
                  <div className="card__bottom">
                    <Link to={INSCRIPCION_ROUTE} className="c-btn c-btn--green">
                      Inscríbete ahora
                    </Link>
                    <Link to="#courses-cards" className="c-btn c-btn--green-border">
                      Ver fechas
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="course-card">
                  <div className="card__header">
                    <h3 className="header__title">Discount Spanish Lessons</h3>
                    <p className="header__description">Presencial</p>
                  </div>
                  <div className="card__center">
                    <div className="left">
                      <h4 className="left__title">2-3 clases por semana</h4>
                      <h4 className="left__price">
                        70€<span>/mes</span>
                      </h4>
                    </div>
                    <p className="note"></p>
                  </div>
                  <div className="card__body"></div>
                  <div className="card__bottom">
                    <Link to={INSCRIPCION_ROUTE} className="c-btn c-btn--green">
                      Inscríbete ahora
                    </Link>
                    <Link to="#courses-cards" className="c-btn c-btn--green-border">
                      Ver fechas
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="third-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h2 className="third-section__title c-title-34">
                  ¿Qué está incluido en el precio?
                </h2>
              </div>
              <div className="col-md-7">
                <div className="board">
                  <h3 className="board__title">En todos nuestros cursos recibirás:</h3>
                  <Checkmark>
                    Todo el material necesario del curso – libros a tu disponibilidad, fotocopias,
                    carpetas, recursos, etc.
                  </Checkmark>
                  <Checkmark>Formación profesional y personalizada.</Checkmark>
                  <Checkmark>Pequeños grupos de aprendices: máximo de 8 por clase.</Checkmark>
                  <Checkmark>Servicio de orientación profesional.</Checkmark>
                  <Checkmark>
                    Aulas modernas con acceso a nuestros recursos – pizarras multimedia,
                    proyectores, ordenadores y biblioteca.
                  </Checkmark>
                  <Checkmark>
                    Certificado de calidad en la enseñanza de español y una valoración final de
                    curso.
                  </Checkmark>
                  <Checkmark>
                    Prácticas con estudiantes de diferentes nacionalidades – diversidad en grupos
                    pequeños.
                  </Checkmark>
                  <Checkmark>
                    Acceso a recursos profesionales – Español para los negocios, de la salud, del
                    turismo, etc.
                  </Checkmark>
                  <Checkmark>Contactos de trabajo y ayuda personalizada.</Checkmark>
                  <Checkmark>Servicio de alojamiento asequible y céntrico.</Checkmark>
                  <Checkmark>
                    Personal profesional y dedicado para ayudarte en tu nueva aventura.
                  </Checkmark>
                  <Checkmark>Café, té, infusiones y fruta fresca.</Checkmark>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fourth-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h2 className="fourth-section__title c-title-34">
                  ¿Qué no está incluido en el precio?
                </h2>
              </div>
              <div className="col-md-7">
                <div className="board">
                  <h3 className="board__title">Nuestros cursos no incluyen:</h3>
                  <Checkmark isExluded>El coste del alojamiento.</Checkmark>
                  <Checkmark isExluded>Transporte.</Checkmark>
                  <Checkmark isExluded>Comida.</Checkmark>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="fifth-section c-section-py-80">
          <div className="container">
            <div className="row no-gutters banner">
              <div className="col-lg-7 order-2 order-lg-1">
                <div className="banner__inner">
                  <h3 className="banner__title">Pago de matrícula</h3>
                  <ul className="banner__list">
                    <li>350€ pago de señal para reservar tu plaza.</li>

                    <li>Abonas el resto del importe la semana antes de tu curso.</li>
                    <li>
                      Los descuentos solo se aplican si pagas el importe total cuando reservas la
                      plaza.
                    </li>
                  </ul>
                  <p className="banner__par">
                    Puedes pagar tu curso con tarjeta de crédito/débito, efectivo, Transferwise, o
                    transferencia bancaria. Una vez te confirmamos la plaza, te enviaremos un enlace
                    de pago. Por favor lee nuestra política de devoluciones.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 order-1 order-lg-2">
                <img src={imgSecSection} alt="Pago de matrícula" />
              </div>
            </div>
          </div>
        </div>
        <div className="sixth-section c-section-py-80" id="courses-cards">
          <div className="container">
            <div className="row">
              <div className="col">
                <CourseSection isFilterable title="Próximos cursos" cardData={allPrismicCourses} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(
      filter: { data: { show_on_these_pages: { elemMatch: { page: { eq: "fechas-y-tarifas" } } } } }
      sort: { order: ASC, fields: data___from }
    ) {
      edges {
        node {
          id
          data {
            name {
              text
            }
            from(formatString: "D MMMM YYYY", locale: "es")
            to(formatString: "D MMMM YYYY", locale: "es")
            price
            is_price_per_month
          }
        }
      }
    }
  }
`;

export default FechasYTarifas;
