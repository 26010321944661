import React from 'react';

import iExclude from '../../assets/images/checkmark-exclude.svg';
import iInclude from '../../assets/images/checkmark-include.svg';

function Checkmark({ isExluded = false, children }) {
  return (
    <div className="tick">
      <img className="tick__mark" src={isExluded ? iExclude : iInclude} alt="checkmark" />
      <p className="tick__body c-text-15">{children}</p>
    </div>
  );
}

export default Checkmark;
